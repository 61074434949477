export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },
    {
        title: 'Staff Name',
        key: 'StaffName',
        sortable: true,
        minWidth: 100,
    },
	{
        title: 'Date',
        key: 'Date',
		sortable: true,
        minWidth: 100,
    },    
    {
        title: 'Start Time',
        key: 'StartTime',
        sortable: true,
        minWidth: 100,
    },
    {
        title: 'End Time',
        key: 'EndTime',
        sortable: true,
        minWidth: 100,
    },
    {
        title: 'Is Working',
        key: 'IsRemoveFromShift',
        sortable: true,
        minWidth: 100,
    },
]
